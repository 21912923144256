<template>
    <div class="UserGroupsEdit full-height pa-4 d-flex flex-column ">
        <div class="d-flex full-height-footerButtons">
            <v-form ref="form">
                <div class="content-area pa-5 flex-grow-1 bg-white">
                    <v-row class="d-flex ">
                        <v-col cols="6" class="pa-0 pr-4">
                            <v-row class="d-flex align-center">
                                <v-col class="col-4">
                                    <p>{{ $t('system.userManagement.groupname') }}</p>
                                </v-col>
                                <v-col class="col-8">
                                    <text-input inputName="editGroup-name" @onInputFocus="onInputFocus"
                                                :readonly="!editable || !checkUserRights('userManagementEdit')"
                                                :class="{'noAction': !editable || !checkUserRights('userManagementEdit')}"
                                                :rules="[v => !!v || $t('validation.required'), rules.alphanumericName, rules.maxLengthName]"/>
                                </v-col>
                            </v-row>
                            <v-row class="d-flex align-center">
                                <v-col class="col-4">
                                    <p>{{ $t('system.userManagement.gid') }}</p>
                                </v-col>
                                <v-col class="col-8">
                                    <text-input inputName="editGroup-gid" @onInputFocus="onInputFocus" onlyNumbers
                                                :readonly="!editable || !checkUserRights('userManagementEdit')"
                                                :class="{'noAction': !editable || !checkUserRights('userManagementEdit')}"
                                                :rules="[rules.alphanumericValue, rules.maxLengthValue]"/>
                                </v-col>
                            </v-row>
                            <v-row class="d-flex align-center">
                                <v-col class="col-4">
                                    <switch-on-off name="editGroup-autoLogoffStatus"
                                                   :disabled="!editable || !checkUserRights('userManagementEdit')"
                                                   :class="{'active': autoLogoffStatus}" class="mr-4"/>
                                </v-col>
                                <v-col class="col-8">
                                    <p>{{ $t('system.userManagement.autoLogoff') }}</p>
                                </v-col>
                            </v-row>
                            <v-row class="d-flex align-center">
                                <v-col class="col-4" :class="{'disabled': !editable || !autoLogoffStatus}">
                                    <p>{{ $t('system.userManagement.autoLogoffAfter') }}</p>
                                </v-col>
                                <v-col class="col-8">
                                    <v-dialog ref="autoLogoffDialog" v-model="autoLogoffModal"
                                              :return-value.sync="editGroup.autoLogoffTime" persistent width="350px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="editGroup.autoLogoffTime"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                hide-details="auto"
                                                outlined
                                                :disabled="!autoLogoffStatus"
                                                class="is-modal"
                                                :class="{'noAction': !editable || !checkUserRights('userManagementEdit')}"
                                                :rules="[rules.autoLogoffTime]"
                                                id="autoLogoffModal"
                                            ></v-text-field>
                                        </template>
                                        <v-time-picker
                                            v-model="editGroup.autoLogoffTime"
                                            scrollable
                                            header-color="disiDarkblue"
                                            width="350"
                                            format="24hr"
                                            use-seconds
                                            id="autoLogoffModal-timepicker"
                                        >
                                            <v-btn elevation="0" class="content-btn small flex-grow-0 mr-2"
                                                   @click="autoLogoffModal = false;">{{ $t('footerButton.cancel') }}
                                            </v-btn>
                                            <v-btn elevation="0" class="content-btn small flex-grow-0 ml-2"
                                                   @click="$refs.autoLogoffDialog.save(editGroup.autoLogoffTime)">
                                                {{ $t('footerButton.save') }}
                                            </v-btn>
                                        </v-time-picker>
                                    </v-dialog>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="6" class="pa-0 pl-4">
                            <v-row class="d-flex align-center">
                                <v-col class="col-4">
                                    <p>{{ $t('system.userManagement.loginAttempts') }}</p>
                                </v-col>
                                <v-col class="col-8">
                                    <text-input inputName="editGroup-loginAttempts" @onInputFocus="onInputFocus"
                                                onlyNumbers
                                                :readonly="!editable || !checkUserRights('userManagementEdit')"
                                                :class="{'noAction': !editable || !checkUserRights('userManagementEdit')}"
                                                :rules="[rules.login]"/>
                                </v-col>
                            </v-row>
                            <v-row class="d-flex align-center">
                                <v-col class="col-4">
                                    <switch-on-off name="editGroup-complexPassword"
                                                   :disabled="!editable || !checkUserRights('userManagementEdit')"
                                                   :class="{'active': complexPassword}" class="mr-4"/>
                                </v-col>
                                <v-col class="col-8">
                                    <p class="doubleline d-flex align-center">
                                        {{ $t('system.userManagement.complexPassword') }}</p>
                                </v-col>
                            </v-row>
                            <v-row class="d-flex align-center">
                                <v-col class="col-4">
                                    <switch-on-off name="editGroup-status"
                                                   :disabled="!checkUserRights('userManagementEdit')"
                                                   :class="{'active': status}" class="mr-4"/>
                                </v-col>
                                <v-col class="col-8">
                                    <p>{{ $t('general.status') }}</p>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-row class="d-flex">
                        <v-col cols="12" class="pa-0">
                            <v-row class="d-flex align-center border-bottom ml-3 mr-1">
                                <v-col class="col-6 pl-0">
                                    <h2>{{ $t('system.userManagement.accessRights') }}</h2>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-row class="d-flex " :class="{'noAction': !editable || !checkUserRights('userManagementEdit')}">
                        <v-col cols="6" class="pa-0 pr-4">
                            <v-row class="d-flex align-center border-bottom ml-3 pb-3 pt-3">
                                <v-col class="col-12 pl-0">
                                    <input class="checkbox-group" type="checkbox" id="testMonitor" value="testMonitor"
                                           v-model="editGroup.groupPermissions" @click="disableChildren('testMonitor')"
                                           :disabled="!editable || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': !editable || !checkUserRights('userManagementEdit')}">
                                    <label for="testMonitor">
                                        {{ $t('system.userManagement.testMonitor') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="quickTest" value="quickTest"
                                           v-model="editGroup.groupPermissions" parent="testMonitor"
                                           :disabled="parentChecked('testMonitor') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('testMonitor') || !checkUserRights('userManagementEdit')}"
                                           @click="disableChildren('quickTest')">
                                    <label for="quickTest">
                                        {{ $t('quickTest.quickTest') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 ml-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="quickTestPause"
                                           value="quickTestPause" v-model="editGroup.groupPermissions"
                                           parent="quickTest" topParent="testMonitor"
                                           :disabled="parentChecked('testMonitor') || parentChecked('quickTest') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('testMonitor') || parentChecked('quickTest') || !checkUserRights('userManagementEdit')}">
                                    <label for="quickTestPause">
                                        {{ $t('system.userManagement.pause') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 ml-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="quickTestCancel"
                                           value="quickTestCancel" v-model="editGroup.groupPermissions"
                                           parent="quickTest" topParent="testMonitor"
                                           :disabled="parentChecked('testMonitor') || parentChecked('quickTest') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('testMonitor') || parentChecked('quickTest') || !checkUserRights('userManagementEdit')}">
                                    <label for="quickTestCancel">
                                        {{ $t('system.userManagement.cancel') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="productTest" value="productTest"
                                           v-model="editGroup.groupPermissions" parent="testMonitor"
                                           :disabled="parentChecked('testMonitor') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('testMonitor') || !checkUserRights('userManagementEdit')}"
                                           @click="disableChildren('productTest')">
                                    <label for="productTest">
                                        {{ $t('productTest.productTest') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 ml-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="productTestMethod"
                                           value="productTestMethod" v-model="editGroup.groupPermissions"
                                           parent="productTest" topParent="testMonitor"
                                           :disabled="parentChecked('testMonitor') || parentChecked('productTest') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('testMonitor') || parentChecked('productTest') || !checkUserRights('userManagementEdit')}">
                                    <label for="productTestMethod">
                                        {{ $t('system.userManagement.productTestMethod') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 ml-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="productTestPause"
                                           value="productTestPause" v-model="editGroup.groupPermissions"
                                           parent="productTest" topParent="testMonitor"
                                           :disabled="parentChecked('testMonitor') || parentChecked('productTest') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('testMonitor') || parentChecked('productTest') || !checkUserRights('userManagementEdit')}">
                                    <label for="productTestPause">
                                        {{ $t('system.userManagement.pause') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 ml-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="productTestCancel"
                                           value="productTestCancel" v-model="editGroup.groupPermissions"
                                           parent="productTest" topParent="testMonitor"
                                           :disabled="parentChecked('testMonitor') || parentChecked('productTest') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('testMonitor') || parentChecked('productTest') || !checkUserRights('userManagementEdit')}">
                                    <label for="productTestCancel">
                                        {{ $t('system.userManagement.cancel') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="heater" value="heater"
                                           v-model="editGroup.groupPermissions" parent="testMonitor"
                                           :disabled="parentChecked('testMonitor') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('testMonitor') || !checkUserRights('userManagementEdit')}">
                                    <label for="heater">
                                        {{ $t('system.userManagement.heater') }}
                                    </label>
                                </v-col>
                            </v-row>
                            <v-row class="d-flex align-center border-bottom ml-3 pb-3 pt-3">
                                <v-col class="col-12 pl-0">
                                    <input class="checkbox-group" type="checkbox" id="products" value="products"
                                           v-model="editGroup.groupPermissions" @click="disableChildren('products')"
                                           :disabled="!editable || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': !editable || !checkUserRights('userManagementEdit')}">
                                    <label for="products">
                                        {{ $t('products.products') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="productsEdit" value="productsEdit"
                                           v-model="editGroup.groupPermissions" parent="products"
                                           :disabled="parentChecked('products') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('products') || !checkUserRights('userManagementEdit')}">
                                    <label for="productsEdit">
                                        {{ $t('system.userManagement.createEdit') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="productsPrint"
                                           value="productsPrint" v-model="editGroup.groupPermissions" parent="products"
                                           :disabled="parentChecked('products') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('products') || !checkUserRights('userManagementEdit')}">
                                    <label for="productsPrint">
                                        {{ $t('system.userManagement.print') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="productsDelete"
                                           value="productsDelete" v-model="editGroup.groupPermissions" parent="products"
                                           :disabled="parentChecked('products') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('products') || !checkUserRights('userManagementEdit')}">
                                    <label for="productsDelete">
                                        {{ $t('system.userManagement.delete') }}
                                    </label>
                                </v-col>
                            </v-row>
                            <v-row class="d-flex align-center border-bottom ml-3 pb-3 pt-3">
                                <v-col class="col-12 pl-0">
                                    <input class="checkbox-group" type="checkbox" id="methods" value="methods"
                                           v-model="editGroup.groupPermissions" @click="disableChildren('methods')"
                                           :disabled="!editable || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': !editable || !checkUserRights('userManagementEdit')}">
                                    <label for="methods">
                                        {{ $t('methods.methods') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="methodsEdit" value="methodsEdit"
                                           v-model="editGroup.groupPermissions" parent="methods"
                                           :disabled="parentChecked('methods') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('methods') || !checkUserRights('userManagementEdit')}">
                                    <label for="methodsEdit">
                                        {{ $t('system.userManagement.createEdit') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="methodsPrint" value="methodsPrint"
                                           v-model="editGroup.groupPermissions" parent="methods"
                                           :disabled="parentChecked('methods') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('methods') || !checkUserRights('userManagementEdit')}">
                                    <label for="methodsPrint">
                                        {{ $t('system.userManagement.print') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="methodsDelete"
                                           value="methodsDelete" v-model="editGroup.groupPermissions" parent="methods"
                                           :disabled="parentChecked('methods') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('methods') || !checkUserRights('userManagementEdit')}">
                                    <label for="methodsDelete">
                                        {{ $t('system.userManagement.delete') }}
                                    </label>
                                </v-col>
                            </v-row>
                            <v-row class="d-flex align-center border-bottom ml-3 mr-n3 pb-3 pt-3">
                                <v-col class="col-12 pl-0">
                                    <input class="checkbox-group" type="checkbox" id="reports" value="reports"
                                           v-model="editGroup.groupPermissions" @click="disableChildren('reports')"
                                           :disabled="!editable || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': !editable || !checkUserRights('userManagementEdit')}">
                                    <label for="reports">
                                        {{ $t('report.reports') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="reportsPrint" value="reportsPrint"
                                           v-model="editGroup.groupPermissions" parent="reports"
                                           :disabled="parentChecked('reports') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('reports') || !checkUserRights('userManagementEdit')}">
                                    <label for="reportsPrint">
                                        {{ $t('system.userManagement.print') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="reportsExport"
                                           value="reportsExport" v-model="editGroup.groupPermissions" parent="reports"
                                           :disabled="parentChecked('reports') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('reports') || !checkUserRights('userManagementEdit')}">
                                    <label for="reportsExport">
                                        {{ $t('system.userManagement.export') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="reportsDelete"
                                           value="reportsDelete" v-model="editGroup.groupPermissions" parent="reports"
                                           :disabled="parentChecked('reports') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('reports') || !checkUserRights('userManagementEdit')}">
                                    <label for="reportsDelete">
                                        {{ $t('system.userManagement.delete') }}
                                    </label>
                                </v-col>
                            </v-row>
                            <v-row class="d-flex align-center border-bottom ml-3 pb-3 pt-3">
                                <v-col class="col-12 pl-0">
                                    <input class="checkbox-group" type="checkbox" id="adjCal" value="adjCal"
                                           v-model="editGroup.groupPermissions" @click="disableChildren('adjCal')"
                                           :disabled="!editable || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': !editable || !checkUserRights('userManagementEdit')}">
                                    <label for="adjCal">
                                        {{ $t('adjCal.adjCal') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="adjustment" value="adjustment"
                                           v-model="editGroup.groupPermissions" parent="adjCal"
                                           :disabled="parentChecked('adjCal') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('adjCal') || !checkUserRights('userManagementEdit')}">
                                    <label for="adjustment">
                                        {{ $t('adjCal.adjustment') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="calibration" value="calibration"
                                           v-model="editGroup.groupPermissions" parent="adjCal"
                                           :disabled="parentChecked('adjCal') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('adjCal') || !checkUserRights('userManagementEdit')}">
                                    <label for="calibration">
                                        {{ $t('adjCal.calibration') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="adjCalReport" value="adjCalReport"
                                           v-model="editGroup.groupPermissions" parent="adjCal"
                                           :disabled="parentChecked('adjCal') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('adjCal') || !checkUserRights('userManagementEdit')}"
                                           @click="disableChildren('adjCalReport')">
                                    <label for="adjCalReport">
                                        {{ $t('report.reports') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 ml-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="adjCalPrint" value="adjCalPrint"
                                           v-model="editGroup.groupPermissions" parent="adjCalReport" topParent="adjCal"
                                           :disabled="parentChecked('adjCalReport') || parentChecked('adjCal') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('adjCalReport') || parentChecked('adjCal') || !checkUserRights('userManagementEdit')}">
                                    <label for="adjCalPrint">
                                        {{ $t('system.userManagement.print') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 ml-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="adjCalExport" value="adjCalExport"
                                           v-model="editGroup.groupPermissions" parent="adjCalReport" topParent="adjCal"
                                           :disabled="parentChecked('adjCalReport') || parentChecked('adjCal') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('adjCalReport') || parentChecked('adjCal') || !checkUserRights('userManagementEdit')}">
                                    <label for="adjCalExport">
                                        {{ $t('system.userManagement.export') }}
                                    </label>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="6" class="pa-0 pl-4">
                            <v-row class="d-flex align-center border-bottom ml-3 pb-3 pt-3">
                                <v-col class="col-12 pl-0">
                                    <input class="checkbox-group" type="checkbox" id="system" value="system"
                                           v-model="editGroup.groupPermissions" @click="disableChildren('system')"
                                           :disabled="!editable || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': !editable || !checkUserRights('userManagementEdit')}">
                                    <label for="system">
                                        {{ $t('system.system') }}
                                    </label>
                                </v-col>

                                <v-col class="col-12 pl-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="systemCalSettings"
                                           value="systemCalSettings" v-model="editGroup.groupPermissions"
                                           parent="system"
                                           :disabled="parentChecked('system') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('system') || !checkUserRights('userManagementEdit')}"
                                           @click="disableChildren('systemCalSettings')">
                                    <label for="systemCalSettings">
                                        {{ $t('system.calSettings.calSettings') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 ml-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="systemCalSettingsEdit"
                                           value="systemCalSettingsEdit" v-model="editGroup.groupPermissions"
                                           parent="systemCalSettings" topParent="system"
                                           :disabled="parentChecked('systemCalSettings') || parentChecked('system') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('systemCalSettings') || parentChecked('system') || !checkUserRights('userManagementEdit')}">
                                    <label for="systemCalSettingsEdit">
                                        {{ $t('system.userManagement.edit') }}
                                    </label>
                                </v-col>

                                <v-col class="col-12 pl-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="systemPDF" value="systemPDF"
                                           v-model="editGroup.groupPermissions" parent="system"
                                           :disabled="parentChecked('system') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('system') || !checkUserRights('userManagementEdit')}"
                                           @click="disableChildren('systemPDF')">
                                    <label for="systemPDF">
                                        {{ $t('system.pdfExport.pdfExport') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 ml-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="systemPDFEdit"
                                           value="systemPDFEdit" v-model="editGroup.groupPermissions" parent="systemPDF"
                                           topParent="system"
                                           :disabled="parentChecked('systemPDF') || parentChecked('system') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('systemPDF') || parentChecked('system') || !checkUserRights('userManagementEdit')}">
                                    <label for="systemPDFEdit">
                                        {{ $t('system.userManagement.edit') }}
                                    </label>
                                </v-col>

                                <v-col class="col-12 pl-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="systemAudit" value="systemAudit"
                                           v-model="editGroup.groupPermissions" parent="system"
                                           :disabled="parentChecked('system') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('system') || !checkUserRights('userManagementEdit')}"
                                           @click="disableChildren('systemAudit') || !checkUserRights('userManagementEdit')">
                                    <label for="systemAudit">
                                        {{ $t('system.audit.auditTrail') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 ml-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="systemAuditPrint"
                                           value="systemAuditPrint" v-model="editGroup.groupPermissions"
                                           parent="systemAudit" topParent="system"
                                           :disabled="parentChecked('systemAudit') || parentChecked('system') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('systemAudit') || parentChecked('system') || !checkUserRights('userManagementEdit')}">
                                    <label for="systemAuditPrint">
                                        {{ $t('system.userManagement.print') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 ml-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="systemAuditExport"
                                           value="systemAuditExport" v-model="editGroup.groupPermissions"
                                           parent="systemAudit" topParent="system"
                                           :disabled="parentChecked('systemAudit') || parentChecked('system') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('systemAudit') || parentChecked('system') || !checkUserRights('userManagementEdit')}">
                                    <label for="systemAuditExport">
                                        {{ $t('system.userManagement.export') }}
                                    </label>
                                </v-col>

                                <v-col class="col-12 pl-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="media" value="media"
                                           v-model="editGroup.groupPermissions" parent="system"
                                           :disabled="parentChecked('system') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('system') || !checkUserRights('userManagementEdit')}"
                                           @click="disableChildren('media')">
                                    <label for="media">
                                        {{ $t('system.media.media') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 ml-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="mediaEdit" value="mediaEdit"
                                           v-model="editGroup.groupPermissions" parent="media" topParent="system"
                                           :disabled="parentChecked('media') || parentChecked('system') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('media') || parentChecked('system') || !checkUserRights('userManagementEdit')}">
                                    <label for="mediaEdit">
                                        {{ $t('system.userManagement.createEdit') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 ml-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="mediaDelete" value="mediaDelete"
                                           v-model="editGroup.groupPermissions" parent="media" topParent="system"
                                           :disabled="parentChecked('media') || parentChecked('system') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('media') || parentChecked('system') || !checkUserRights('userManagementEdit')}">
                                    <label for="mediaDelete">
                                        {{ $t('system.userManagement.delete') }}
                                    </label>
                                </v-col>

                                <v-col class="col-12 pl-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="systemNetwork"
                                           value="systemNetwork" v-model="editGroup.groupPermissions" parent="system"
                                           :disabled="parentChecked('system') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('system') || !checkUserRights('userManagementEdit')}"
                                           @click="disableChildren('systemNetwork')">
                                    <label for="systemNetwork">
                                        {{ $t('system.network.network') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 ml-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="systemNetworkEdit"
                                           value="systemNetworkEdit" v-model="editGroup.groupPermissions"
                                           parent="systemNetwork" topParent="system"
                                           :disabled="parentChecked('systemNetwork') || parentChecked('system') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('systemNetwork') || parentChecked('system') || !checkUserRights('userManagementEdit')}">
                                    <label for="systemNetworkEdit">
                                        {{ $t('system.userManagement.edit') }}
                                    </label>
                                </v-col>

                                <v-col class="col-12 pl-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="systemService"
                                           value="systemService" v-model="editGroup.groupPermissions" parent="system"
                                           :disabled="parentChecked('system') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('system') || !checkUserRights('userManagementEdit')}"
                                           @click="disableChildren('systemService')">
                                    <label for="systemService">
                                        {{ $t('system.service.service') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 ml-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="systemServiceEdit"
                                           value="systemServiceEdit" v-model="editGroup.groupPermissions"
                                           parent="systemService" topParent="system"
                                           :disabled="parentChecked('systemService') || parentChecked('system') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('systemService') || parentChecked('system') || !checkUserRights('userManagementEdit')}">
                                    <label for="systemServiceEdit">
                                        {{ $t('system.userManagement.edit') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 ml-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="systemServiceLicense"
                                           value="systemServiceLicense" v-model="editGroup.groupPermissions"
                                           parent="systemService" topParent="system"
                                           :disabled="parentChecked('systemService') || parentChecked('system') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('systemService') || parentChecked('system') || !checkUserRights('userManagementEdit')}">
                                    <label for="systemServiceLicense">
                                        {{ $t('system.userManagement.license') }}
                                    </label>
                                </v-col>

                                <v-col class="col-12 pl-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="systemHeaterSettings"
                                           value="systemHeaterSettings" v-model="editGroup.groupPermissions"
                                           parent="system"
                                           :disabled="parentChecked('system') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('system') || !checkUserRights('userManagementEdit')}"
                                           @click="disableChildren('systemHeaterSettings')">
                                    <label for="systemHeaterSettings">
                                        {{ $t('system.heater.heaterSettings') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 ml-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="systemHeaterSettingsEdit"
                                           value="systemHeaterSettingsEdit" v-model="editGroup.groupPermissions"
                                           parent="systemHeaterSettings" topParent="system"
                                           :disabled="parentChecked('systemHeaterSettings') || parentChecked('system') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('systemHeaterSettings') || parentChecked('system') || !checkUserRights('userManagementEdit')}">
                                    <label for="systemHeaterSettingsEdit">
                                        {{ $t('system.userManagement.edit') }}
                                    </label>
                                </v-col>

                                <v-col class="col-12 pl-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="systemShare" value="systemShare"
                                           v-model="editGroup.groupPermissions" parent="system"
                                           :disabled="parentChecked('system') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('system') || !checkUserRights('userManagementEdit')}"
                                           @click="disableChildren('systemShare')">
                                    <label for="systemShare">
                                        {{ $t('system.share.path') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 ml-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="systemShareEdit"
                                           value="systemShareEdit" v-model="editGroup.groupPermissions"
                                           parent="systemShare" topParent="system"
                                           :disabled="parentChecked('systemShare') || parentChecked('system') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('systemShare') || parentChecked('system') || !checkUserRights('userManagementEdit')}">
                                    <label for="systemShareEdit">
                                        {{ $t('system.userManagement.createEdit') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 ml-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="systemShareDelete"
                                           value="systemShareDelete" v-model="editGroup.groupPermissions"
                                           parent="systemShare" topParent="system"
                                           :disabled="parentChecked('systemShare') || parentChecked('system') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('systemShare') || parentChecked('system') || !checkUserRights('userManagementEdit')}">
                                    <label for="systemShareDelete">
                                        {{ $t('system.userManagement.delete') }}
                                    </label>
                                </v-col>

                                <v-col class="col-12 pl-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="firmwareupdate"
                                           value="firmwareupdate" v-model="editGroup.groupPermissions" parent="system"
                                           :disabled="parentChecked('system') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('system') || !checkUserRights('userManagementEdit')}"
                                           @click="disableChildren('firmwareupdate')">
                                    <label for="firmwareupdate">
                                        {{ $t('system.firmwareupdate.firmwareupdate') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 ml-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="firmwareupdateStart"
                                           value="firmwareupdateStart" v-model="editGroup.groupPermissions"
                                           parent="firmwareupdate" topParent="system"
                                           :disabled="parentChecked('firmwareupdate') || parentChecked('system') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('firmwareupdate') || parentChecked('system') || !checkUserRights('userManagementEdit')}">
                                    <label for="firmwareupdateStart">
                                        {{ $t('system.userManagement.start') }}
                                    </label>
                                </v-col>

                                <v-col class="col-12 pl-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="systemStations"
                                           value="systemStations" v-model="editGroup.groupPermissions" parent="system"
                                           :disabled="parentChecked('system') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('system') || !checkUserRights('userManagementEdit')}"
                                           @click="disableChildren('systemStations')">
                                    <label for="systemStations">
                                        {{ $t('system.stationsSettings.stations') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 ml-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="systemStationsEdit"
                                           value="systemStationsEdit" v-model="editGroup.groupPermissions"
                                           parent="systemStations" topParent="system"
                                           :disabled="parentChecked('systemStations') || parentChecked('system') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('systemStations') || parentChecked('system') || !checkUserRights('userManagementEdit')}">
                                    <label for="systemStationsEdit">
                                        {{ $t('system.userManagement.edit') }}
                                    </label>
                                </v-col>

                                <v-col class="col-12 pl-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="systemPrinter"
                                           value="systemPrinter" v-model="editGroup.groupPermissions" parent="system"
                                           :disabled="parentChecked('system') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('system') || !checkUserRights('userManagementEdit')}"
                                           @click="disableChildren('systemPrinter')">
                                    <label for="systemPrinter">
                                        {{ $t('system.printer.printer') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 ml-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="systemPrinterEdit"
                                           value="systemPrinterEdit" v-model="editGroup.groupPermissions"
                                           parent="systemPrinter" topParent="system"
                                           :disabled="parentChecked('systemPrinter') || parentChecked('system') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('systemPrinter') || parentChecked('system') || !checkUserRights('userManagementEdit')}">
                                    <label for="systemPrinterEdit">
                                        {{ $t('system.userManagement.edit') }}
                                    </label>
                                </v-col>

                                <v-col class="col-12 pl-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="userManagement"
                                           value="userManagement" v-model="editGroup.groupPermissions" parent="system"
                                           :disabled="parentChecked('system') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('system') || !checkUserRights('userManagementEdit')}"
                                           @click="disableChildren('userManagement')">
                                    <label for="userManagement">
                                        {{ $t('system.userManagement.userManagement') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 ml-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="userManagementEdit"
                                           value="userManagementEdit" v-model="editGroup.groupPermissions"
                                           parent="userManagement" topParent="system"
                                           :disabled="parentChecked('userManagement') || parentChecked('system') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('userManagement') || parentChecked('system') || !checkUserRights('userManagementEdit')}">
                                    <label for="userManagementEdit">
                                        {{ $t('system.userManagement.createEdit') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 ml-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="userManagementPrint"
                                           value="userManagementPrint" v-model="editGroup.groupPermissions"
                                           parent="userManagement" topParent="system"
                                           :disabled="parentChecked('userManagement') || parentChecked('system') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('userManagement') || parentChecked('system') || !checkUserRights('userManagementEdit')}">
                                    <label for="userManagementPrint">
                                        {{ $t('system.userManagement.print') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 ml-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="userManagementDelete"
                                           value="userManagementDelete" v-model="editGroup.groupPermissions"
                                           parent="userManagement" topParent="system"
                                           :disabled="parentChecked('userManagement') || parentChecked('system') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('userManagement') || parentChecked('system') || !checkUserRights('userManagementEdit')}">
                                    <label for="userManagementDelete">
                                        {{ $t('system.userManagement.delete') }}
                                    </label>
                                </v-col>

                                <v-col class="col-12 pl-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="backup" value="backup"
                                           v-model="editGroup.groupPermissions" parent="system"
                                           :disabled="parentChecked('system') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('system') || !checkUserRights('userManagementEdit')}"
                                           @click="disableChildren('backup')">
                                    <label for="backup">
                                        {{ $t('system.backup.backup') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 ml-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="backupEdit" value="backupEdit"
                                           v-model="editGroup.groupPermissions" parent="backup" topParent="system"
                                           :disabled="parentChecked('backup') || parentChecked('system') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('backup') || parentChecked('system') || !checkUserRights('userManagementEdit')}">
                                    <label for="backupEdit">
                                        {{ $t('system.userManagement.createEdit') }}
                                    </label>
                                </v-col>

                                <v-col class="col-12 pl-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="systemSettings"
                                           value="systemSettings" v-model="editGroup.groupPermissions" parent="system"
                                           :disabled="parentChecked('system') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('system') || !checkUserRights('userManagementEdit')}"
                                           @click="disableChildren('systemSettings')">
                                    <label for="systemSettings">
                                        {{ $t('system.systemSettings') }}
                                    </label>
                                </v-col>
                                <v-col class="col-12 pl-11 ml-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="systemSettingsEdit"
                                           value="systemSettingsEdit" v-model="editGroup.groupPermissions"
                                           parent="systemSettings" topParent="system"
                                           :disabled="parentChecked('systemSettings') || parentChecked('system') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('systemSettings') || parentChecked('system') || !checkUserRights('userManagementEdit')}">
                                    <label for="systemSettingsEdit">
                                        {{ $t('system.userManagement.edit') }}
                                    </label>
                                </v-col>

                                <v-col class="col-12 pl-11 pt-0">
                                    <input class="checkbox-group" type="checkbox" id="systemInfo" value="systemInfo"
                                           v-model="editGroup.groupPermissions" parent="system"
                                           :disabled="parentChecked('system') || !checkUserRights('userManagementEdit')"
                                           :class="{'v-input--is-readonly': parentChecked('system') || !checkUserRights('userManagementEdit')}">
                                    <label for="systemInfo">
                                        {{ $t('system.systemInfo') }}
                                    </label>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </v-form>
        </div>
        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button buttonFunction="print" :successCheck="showPrintSuccess" :progress="showPrintProgress"
                           :class="{'disabled': printDisabled || !checkUserRights('userManagementPrint')}"
                           @footerButtonClick="print"></footer-button>
            <footer-button buttonFunction="delete"
                           :class="{'disabled': !editable || editGroup.id == null || !checkUserRights('userManagementDelete')}"
                           @footerButtonClick="deleteGroup"></footer-button>
            <footer-button buttonFunction="cancel" @footerButtonClick="goToGroups"></footer-button>
            <footer-button buttonFunction="save" :successCheck="showSaveSuccess" :progress="showSaveProgress"
                           :class="{'disabled': saveDisabled || !checkUserRights('userManagementEdit')}"
                           @footerButtonClick="save"></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
import TextInput from '@/components/TextInput.vue'
import SwitchOnOff from '@/components/SwitchOnOff.vue'
import FooterButton from '@/components/FooterButton.vue'
import {mapState} from "vuex";
import mixins from "@/mixins/mixins";

export default {
    name: 'UserGroupsEdit',
    components: {
        TextInput,
        SwitchOnOff,
        FooterButton,
    },
    props: {},
    data() {
        return {
            autoLogoffModal: false,
            saveDisabled: true,
            showSaveProgress: false,
            showSaveSuccess: false,
            showPrintProgress: false,
            showPrintSuccess: false,
            originalGroup: {},
            rules: {
                alphanumericName: v => {
                    if (v) {
                        let alphanumeric = /^[ A-Za-z0-9]+$/;
                        return alphanumeric.test(v) || `${this.$t('validation.pleaseEnterValidName')}. ${this.$t('validation.noSpecialChar')}`;
                    } else return true;
                },
                alphanumericValue: v => {
                    if (v) {
                        let alphanumeric = /^[ A-Za-z0-9]+$/;
                        return alphanumeric.test(v) || `${this.$t('validation.pleaseEnterValidValue')}. ${this.$t('validation.noSpecialChar')}`;
                    } else return true;
                },
                maxLengthValue: v => {
                    if (v) {
                        return v.length < 24 || `${this.$t('validation.chooseShorterValue')}`;
                    } else return true;
                },
                maxLengthName: v => {
                    if (v) {
                        return v.length < 24 || `${this.$t('validation.chooseShorterName')}`;
                    } else return true;
                },
                login: v => {
                    if (v) {
                        return v <= 10 || `${this.$t('validation.minimum')}: 0, ${this.$t('validation.maximum')}: 10, 0 = ${this.$t('validation.unlimited')}`
                    } else return true;
                },
                autoLogoffTime: v => {
                    if (this.autoLogoffStatus) {
                        if (v && v != '00:00:00') {
                            return !!v;
                        } else {
                            return `${this.$t('validation.required')}`;
                        }
                    } else return true;
                },
            }
        }
    },
    computed: {
        ...mapState([
            'editGroup'
        ]),
        status() {
            return this.$store.state.editGroup.status;
        },
        complexPassword() {
            return this.$store.state.editGroup.complexPassword;
        },
        autoLogoffStatus() {
            return this.$store.state.editGroup.autoLogoffStatus;
        },
        printDisabled() {
            if (this.editGroup.id == null) {
                return true;
            } else {
                return false;
            }
        },
        editable() {
            return this.editGroup.editable;
        },
    },
    methods: {
        goToGroups() {
            this.$router.push('usergroups');
        },
        print() {
            this.showPrintProgress = true;
            this.$store.dispatch('postAxiosNoSetter', ['/admin/group/print', {'id': parseInt(this.editGroup.id)}])
                .then(response => {
                    this.showPrintProgress = false;
                    if (response.status === 200) {
                        this.showPrintSuccess = true;
                        setTimeout(() => {
                            this.showPrintSuccess = false;
                        }, 800)
                    }
                });
        },
        deleteGroup() {
            this.$store.dispatch('postAxiosNoSetter', ['/admin/group/delete', {'id': parseInt(this.editGroup.id)}])
                .then(response => {
                    if (response.status === 200) {
                        this.goToGroups();
                    }
                })
        },
        save() {
            this.$refs.form.validate();
            if (this.$refs.form.validate()) {
                this.showSaveProgress = true;
                if (this.editGroup.id != null) {
                    let Group = {}
                    //optional
                    if (this.editGroup.name != this.originalGroup.name && this.editGroup.name != "") {
                        Group.name = this.editGroup.name;
                    }
                    if (this.editGroup.autoLogoffStatus != this.originalGroup.autoLogoffStatus) {
                        Group.autoLogoffStatus = this.editGroup.autoLogoffStatus;
                    }
                    if (this.editGroup.autoLogoffStatus && this.editGroup.autoLogoffTime != this.originalGroup.autoLogoffTime && this.editGroup.autoLogoffTime != null) {
                        Group.autoLogoffTime = this.timestampFormat(this.editGroup.autoLogoffTime);
                    }
                    if (this.editGroup.loginAttempts != this.originalGroup.loginAttempts && this.editGroup.loginAttempts != "") {
                        Group.loginAttempts = parseInt(this.editGroup.loginAttempts);
                    }
                    if (this.editGroup.complexPassword != this.originalGroup.complexPassword) {
                        Group.complexPassword = this.editGroup.complexPassword;
                    }
                    if (JSON.stringify(this.editGroup.groupPermissions) != JSON.stringify(this.originalGroup.groupPermissions)) {
                        Group.groupPermissions = JSON.parse(JSON.stringify(this.editGroup.groupPermissions));
                    }
                    if (this.editGroup.status != this.originalGroup.status) {
                        Group.status = this.editGroup.status;
                    }
                    if (this.editGroup.gid != this.originalGroup.gid) {
                        Group.gid = parseInt(this.editGroup.gid);
                        if (isNaN(Group.gid)) {
                            Group.gid = null;
                        }
                    }
                    if (Object.keys(Group).length === 0 && Group.constructor === Object) {
                        this.showSaveProgress = false;
                        this.showSaveSuccess = true;
                        this.watcher();
                        setTimeout(() => {
                            this.saveDisabled = true;
                            this.showSaveSuccess = false;
                        }, 800)
                    } else {
                        //must send
                        Group.id = parseInt(this.editGroup.id);
                        this.$store.dispatch('postAxiosNoSetter', ['admin/group/edit', Group])
                            .then(response => {
                                this.showSaveProgress = false;
                                if (response.status === 200) {
                                    this.showSaveSuccess = true;
                                    this.watcher();
                                    setTimeout(() => {
                                        this.saveDisabled = true;
                                        this.showSaveSuccess = false;
                                    }, 800)
                                }
                            })
                    }

                } else {
                    let Group = {
                        //must send
                        "name": this.editGroup.name,
                        "gid": parseInt(this.editGroup.gid),
                        "loginAttempts": parseInt(this.editGroup.loginAttempts),
                        "autoLogoffStatus": this.editGroup.autoLogoffStatus,
                        "autoLogoffTime": this.timestampFormat(this.editGroup.autoLogoffTime),
                        "complexPassword": this.editGroup.complexPassword,
                        "groupPermissions": this.editGroup.groupPermissions,
                        "status": this.editGroup.status
                    }
                    if (isNaN(Group.gid)) {
                        Group.gid = null;
                    }
                    this.$store.dispatch('postAxiosNoSetter', ['admin/group/new', Group])
                        .then(response => {
                            this.showSaveProgress = false;
                            if (response.status === 200) {
                                this.editGroup.id = response.data.id;
                                this.showSaveSuccess = true;
                                this.watcher();
                                setTimeout(() => {
                                    this.saveDisabled = true;
                                    this.showSaveSuccess = false;
                                }, 800)
                            }
                        })
                }
            } else {
                //scroll to error
                setTimeout(() => {
                    let message = document.querySelector('.v-input.error--text');
                    if (message) {
                        let messagePosition = message.getBoundingClientRect();
                        document.querySelector('.content-area').scrollTo({
                            top: messagePosition.top,
                            behavior: 'smooth'
                        });
                    } else {
                        document.querySelector('.content-area').scrollTo({top: 0, behavior: 'smooth'});
                    }
                }, 100)
            }
        },

        disableChildren(parentValue) {
            let parentEle = document.querySelector('#' + parentValue);
            if (!parentEle.checked) {
                let children = document.querySelectorAll(`[parent=${parentValue}]`);
                if (children.length > 0) {
                    children.forEach((child) => {
                        if (child.checked) {
                            let childId = child.id;
                            let index = this.editGroup.groupPermissions.indexOf(childId);
                            this.editGroup.groupPermissions.splice(index, 1);
                            child.checked = false;
                        }
                    })
                }
                let grandchildren = document.querySelectorAll(`[topParent=${parentValue}]`);
                if (grandchildren.length > 0) {
                    grandchildren.forEach((child) => {
                        if (child.checked) {
                            let childId = child.id;
                            let index = this.editGroup.groupPermissions.indexOf(childId);
                            this.editGroup.groupPermissions.splice(index, 1);
                            child.checked = false;
                        }
                    })
                }
            }
        },

        parentChecked(parent) {
            if (this.editGroup.groupPermissions && this.editGroup.groupPermissions.includes(parent)) {
                return false;
            } else {
                return true;
            }
        },

        watcher() {
            //copy editGroup to originalGroup for comparison
            this.originalGroup = JSON.parse(JSON.stringify(this.editGroup));

            let name = this.$watch('$store.state.editGroup.name', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let gid = this.$watch('$store.state.editGroup.gid', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let loginAttempts = this.$watch('$store.state.editGroup.loginAttempts', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let autoLogoffStatus = this.$watch('$store.state.editGroup.autoLogoffStatus', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let autoLogoffTime = this.$watch('$store.state.editGroup.autoLogoffTime', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let complexPassword = this.$watch('$store.state.editGroup.complexPassword', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let groupPermissions = this.$watch('$store.state.editGroup.groupPermissions', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            let status = this.$watch('$store.state.editGroup.status', function () {
                this.saveDisabled = false;
                stopWatch();
            });

            const stopWatch = function () {
                name();
                gid();
                loginAttempts();
                autoLogoffStatus();
                autoLogoffTime();
                complexPassword();
                groupPermissions();
                status();
            }
        },
        // Keyboard functions ------------------ //
        onInputFocus(input, onlyNumbers) {
            this.$emit('onInputFocus', [input.target.id, onlyNumbers])
            this.$vuetify.goTo('#' + input.target.id, {duration: 100, offset: 80, container: ".content-area"});
        },
    },
    mixins: [mixins],
    created() {
        this.$emit("resetInput");
        this.watcher();
    },
    beforeRouteLeave(to, from, next) {
        if (this.saveDisabled) {
            next()
        } else {
            this.$confirm({
                message: this.$t('alerts.unsavedChanges'),
                show: true,
                cancelText: this.$t('general.cancel'),
                continueText: this.$t('general.continue')
            })
                .then(() => {
                    // continue
                    next();
                })
                .catch(() => {
                    // cancel
                });
        }
    },
}
</script>

<style scoped lang="scss">
h2 {
    font-size: 1rem;
    margin-top: 36px;
}

h3 {
    font-size: 1rem;
}

.border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    max-width: calc(100% - 24px);
}

.doubleline {
    min-height: 128px;
}

input[type=checkbox] {
    display: none;

    &.checkbox-group + label {
        display: flex;
        align-items: center;

        &::before {
            content: "";
            display: inline-block;
            width: 24px;
            height: 24px;
            border: 1px solid rgba(0, 0, 0, 0.42);
            margin-right: 20px;
            margin-bottom: 2px;
            transition: all 0.1s ease;
        }
    }

    &[disabled="disabled"].checkbox-group {
        & + label {
            color: $disiBluegreyMedium;

            &::before {
                border: 1px solid $disiBluegreyMedium;
            }
        }
    }
}

.noAction input[type=checkbox].checkbox-group {
    pointer-events: none;

    & + label {
        pointer-events: none;
        color: $disiBluegreyMedium;

        &::before {
            pointer-events: none;
            border: 1px solid $disiBluegreyMedium;
        }
    }
}

input[type=checkbox]:checked.checkbox-group + label {
    &::before {
        background-color: $disiIrisblue;
        border: 1px solid $disiIrisblue;
        background-image: url("../assets/OK_weiss.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 80%;
        transition: all 0.1s ease;
    }
}

.noAction input[type=checkbox]:checked.checkbox-group {
    pointer-events: none;

    & + label {
        color: $disiGrey;
        pointer-events: none;

        &::before {
            pointer-events: none;
            border: 1px solid lighten($disiIrisblue, 30);
            background-color: lighten($disiIrisblue, 30);
        }
    }
}
</style>